import React, { useEffect, useState, useRef } from "react";
import { Plus, Minus, RefreshCcw } from "lucide-react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
} from "react-share";
import iconImage from "../../assets/Icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShareButton = ({ finalResponse }) => {
  const isMobileOrTablet = /Mobi|Android/i.test(window.navigator.userAgent);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleShareClick = () => {
    if (navigator.share && finalResponse) {
      navigator.share({
        title: "Assessment Task",
        text: finalResponse.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML tags if needed
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    // Add event listener to detect clicks outside of the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isMobileOrTablet ? (
        <button
          className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
            finalResponse
              ? "cursor-pointer hover:bg-gray-200"
              : "cursor-not-allowed"
          }`}
          onClick={handleShareClick}
          disabled={!finalResponse}
        >
          <i className="fa-solid fa-share-nodes mr-2"></i>
          Share
        </button>
      ) : (
        <div className="relative" ref={dropdownRef}>
          <button
            className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
              finalResponse
                ? "cursor-pointer hover:bg-gray-200"
                : "cursor-not-allowed"
            }`}
            onClick={() => setDropdownVisible(!dropdownVisible)}
            disabled={!finalResponse}
          >
            <i className="fa-solid fa-share-nodes mr-2"></i>
            Share
          </button>

          {dropdownVisible && (
            <div className="absolute bottom-12 bg-white border border-gray-200 rounded shadow min-w-40">
              <div className="flex flex-col space-y-4 p-2">
                <FacebookShareButton
                  url={window.location.href}
                  quote={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  className="flex items-center gap-2"
                >
                  <FacebookIcon size={20} round={false} /> <span>Facebook</span>
                </FacebookShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                  summary={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  className="flex items-center gap-2"
                >
                  <LinkedinIcon size={20} round={false} /> <span>LinkedIn</span>
                </LinkedinShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  className="flex items-center gap-2"
                >
                  <TwitterIcon size={20} round={false} /> <span>Twitter</span>
                </TwitterShareButton>
                <WhatsappShareButton
                  url={window.location.href}
                  title={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  className="flex items-center gap-2"
                >
                  <WhatsappIcon size={20} round={false} /> <span>Whatsapp</span>
                </WhatsappShareButton>
                <EmailShareButton
                  subject="Assessment Task"
                  body={finalResponse.replace(/<\/?[^>]+(>|$)/g, "")}
                  url=""
                  className="flex items-center gap-2"
                >
                  <EmailIcon size={20} round={false} /> <span>Email</span>
                </EmailShareButton>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const AssessmentTask = () => {
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const [combinePrompt, setCombinePrompt] = useState("");
  const [finalResponse, setFinalResponse] = useState("");
  async function chatgpt(prompt) {
    try {
      const response = await fetch(
        "https://dasboard-backend-production.up.railway.app/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            systemPrompt: `You will act as lesson plan generator.
          
            Always use html tags. Always bold the heading sections using <h4> and its content in <p> tag. Always Use <p> for paragraphs. Directly start from <h2> heading title/topic. Introductory sentence is not required.
  
            Note everything should be in detail. Explanation should be present for all.`,
            userMessage:
              "Always use html tags. Always bold the heading sections using <h4> and its content in <p> tag. Always Use <p> for paragraphs. Directly start from <h2> heading title/topic. Introductory sentence is not required." +
              prompt,
            module: "assessmentTask",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Error in response");
      }
      const result = await response.json();
      console.log(result);
      return result.message;
    } catch (e) {
      toast.error("Error generating content");
      console.error("Error:", e);
    }
  }

  async function generateAndDownloadWord() {
    try {
      const header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
      const footer = "</body></html>";

      const sourceHTML = header + finalResponse + footer;

      const source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(sourceHTML);
      const fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = "document.doc";
      fileDownload.click();
      document.body.removeChild(fileDownload);
    } catch (e) {
      toast.error("Error downloading word file");
      console.error("Error generating or downloading Word document:", e);
    }
  }

  async function generateAndDownloadPDF() {
    try {
      const response = await fetch(
        "https://dasboard-backend-production.up.railway.app/generate-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ htmlContent: finalResponse }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "document.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      toast.error("Error downloading Pdf file");
      console.error("Error generating or downloading Pdf document:", e);
    }
  }

  const [showTooltip, setShowTooltip] = useState(false);
  const copyToClipboard = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    const textToCopy = tempDiv.textContent || tempDiv.innerText || "";

    navigator.clipboard.writeText(textToCopy).then(() => {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    });
  };

  // Field1 start
  const [degreeLevel, setDegreeLevel] = useState("");
  const degreeLevelPrompt = `The activity is to be designed specifically for ${degreeLevel} students.`;
  // Field1 end

  // Field2 start
  const [courseTitle, setCourseTitle] = useState("");
  const courseTitlePrompt = `Course Title: ${courseTitle}`;
  // Field2 end

  // Field3 start
  const [lessonTitle, setLessonTitle] = useState("");
  const lessonTitlePrompt = `Lesson Title: ${lessonTitle}`;
  // Field3 end

  // Field4 start
  const [lessonTopic, setLessonTopic] = useState("");
  const lessonTopicPrompt = `Lesson Topic: ${lessonTopic}`;
  // Field4 end

  // Field14 start
  const [timing, setTiming] = useState("");
  const [otherField14, setOtherField14] = useState("");
  const timingPrompt = `The length of time for the activity should be ${
    timing == "Other" ? `${otherField14}` : timing
  }. `;
  // Field14 end

  // Field5 start
  const [learningOutcomes, setLearningOutcomes] = useState("");
  const learningOutcomesPrompt = `Learning Outcomes: ${learningOutcomes}`;
  // Field5 end

  //Field6 start
  const [teachingStrategy, setTeachingStrategy] = useState([]);
  const [showOtherField6, setShowOtherField6] = useState(false);
  const [otherField6, setOtherField6] = useState("");
  const handleToggleField6 = () => {
    setOtherField6("");
    setShowOtherField6(!showOtherField6);
  };
  const teachingStrategyPrompt = `Teaching Strategy: ${teachingStrategy}${
    otherField6 ? `,${otherField6}` : ""
  }`;
  //Field6 end

  //Field7 start
  const [materials, setMaterials] = useState([]);
  const [showOtherField7, setShowOtherField7] = useState(false);
  const [otherField7, setOtherField7] = useState("");
  const handleToggleField7 = () => {
    setOtherField7("");
    setShowOtherField7(!showOtherField7);
  };
  const materialsPrompt = `Material(s): ${materials}${
    otherField7 ? `,${otherField7}` : ""
  }`;
  //Field7 end

  //Field8 start
  const [deliveryMethod, setDeliveryMethod] = useState([]);
  const [showOtherField8, setShowOtherField8] = useState(false);
  const [otherField8, setOtherField8] = useState("");
  const handleToggleField8 = () => {
    setOtherField8("");
    setShowOtherField8(!showOtherField8);
  };
  const deliveryMethodPrompt = `Delivery Method: ${deliveryMethod}${
    otherField8 ? `,${otherField8}` : ""
  }`;
  //Field8 end

  //Field9 start
  const [instructionalDesignModel, setInstructionalDesignModel] = useState([]);
  const [showOtherField9, setShowOtherField9] = useState(false);
  const [otherField9, setOtherField9] = useState("");
  const handleToggleField9 = () => {
    setOtherField9("");
    setShowOtherField9(!showOtherField9);
  };
  const instructionalDesignModelPrompt = `Instructional Design Model: ${instructionalDesignModel}${
    otherField9 ? `,${otherField9}` : ""
  }`;
  //Field9 end

  // Field10 start
  const [rubricCriteriaLevel, setRubricCriteriaLevel] = useState("");
  const [otherField10, setOtherField10] = useState("");
  const rubricCriteriaLevelPrompt = `Rubrics Criteria Level: ${
    rubricCriteriaLevel == "Other" ? `${otherField10}` : rubricCriteriaLevel
  }`;
  // Field10 end

  //Field11 start
  const [assessmentStrategy, setAssessmentStrategy] = useState([]);
  const assessmentStrategyPrompt = `Assessment Strategies: ${assessmentStrategy}`;
  //Field11 end

  // Field12 start
  const [standard, setStandard] = useState("");
  const [otherField12, setOtherField12] = useState("");
  const standardPrompt = `Standards: ${
    standard == "Other" ? `${otherField12}` : standard
  }.`;
  // Field12 end

  const [errors, setErrors] = useState({
    degreeLevel: false,
    courseTitle: false,
    lessonTitle: false,
    lessonTopic: false,
    timing: false,
    learningOutcomes: false,
    teachingStrategy: false,
    materials: false,
    deliveryMethod: false,
    instructionalDesignModel: false,
    rubricCriteriaLevel: false,
    assessmentStrategy: false,
    standard: false,
  });

  const createPrompt = async () => {
    let formIsValid = true;
    const requiredFields = {
      degreeLevel,
      courseTitle,
      lessonTitle,
      lessonTopic,
      timing,
      learningOutcomes,
      teachingStrategy,
      materials,
      deliveryMethod,
      instructionalDesignModel,
      assessmentStrategy,
      rubricCriteriaLevel,
      standard,
    };
    const newErrors = { ...errors };

    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        formIsValid = false;
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (timing === "Other" && otherField14 === "") {
      formIsValid = false;
      newErrors.timing = true;
    }

    if (
      teachingStrategy.length === 0 &&
      (!showOtherField6 || (showOtherField6 && otherField6 === ""))
    ) {
      formIsValid = false;
      newErrors.teachingStrategy = true;
    }

    if (
      materials.length === 0 &&
      (!showOtherField7 || (showOtherField7 && otherField7 === ""))
    ) {
      formIsValid = false;
      newErrors.materials = true;
    }

    if (
      deliveryMethod.length === 0 &&
      (!showOtherField8 || (showOtherField8 && otherField8 === ""))
    ) {
      formIsValid = false;
      newErrors.deliveryMethod = true;
    }

    if (rubricCriteriaLevel === "Other" && otherField10 === "") {
      formIsValid = false;
      newErrors.rubricCriteriaLevel = true;
    }

    if (
      instructionalDesignModel.length === 0 &&
      (!showOtherField9 || (showOtherField9 && otherField9 === ""))
    ) {
      formIsValid = false;
      newErrors.instructionalDesignModel = true;
    }

    if (assessmentStrategy.length === 0) {
      formIsValid = false;
      newErrors.assessmentStrategy = true;
    }

    if (standard === "Other" && otherField12 === "") {
      formIsValid = false;
      newErrors.standard = true;
    }

    if (formIsValid) {
      try {
        setLoading1(true);
        setTimeout(() => {
          setCombinePrompt(`Create an assessment task that incorporates the following criteria:
              
${degreeLevelPrompt}
            
${courseTitlePrompt}
            
${lessonTitlePrompt}
            
${lessonTopicPrompt}

${timingPrompt}
            
${learningOutcomesPrompt}
            
${teachingStrategyPrompt}

${materialsPrompt}

${deliveryMethodPrompt}

${instructionalDesignModelPrompt}

${rubricCriteriaLevelPrompt}

${assessmentStrategyPrompt}

${standardPrompt}

Provide the following in your lesson plan or assessment task:

Title and Description: A clear title and a brief description of the activity's objectives and expected outcomes.

Step-by-Step Guide: A sequential guide for conducting the activity, ensuring clarity in execution.

Materials and Resources: A comprehensive list of all materials and resources needed for the lesson, such as handouts, technological tools and reference materials.

Instructions for Activities: Detailed instructions for the teaching and learning activities, including the order in which they should be carried out.

Engagement Strategies: Tailored strategies for engaging the entire class, considering the size and diversity of the student group.

Rubrics for Assessment: Develop a comprehensive criterion-referenced assessment rubric in table format. The rubric should:

1. Outline Clear Expectations: Define specific criteria that will be assessed.

2. Include Specific Levels of Performance: Provide multiple levels of performance for each criterion.

3. Assign Corresponding Scores: Allocate scores to each performance level.

4. Provide Detailed Descriptors: Offer detailed descriptions for what each level of performance entails for every criterion. 

Assessment Methods: An explanation of the formative and summative assessments to be used for evaluating the learning objectives, detailing how student learning will be monitored and measured.
`);

          setLoading1(false);
        }, 3000);
      } catch (error) {
        setLoading1(false);
        console.error(error);
      }
    }
  };

  function resetForm() {
    setDegreeLevel("");
    setCourseTitle("");
    setCourseTitle("");
    setLessonTitle("");
    setLessonTopic("");
    setTiming("");
    setOtherField14("");
    setLearningOutcomes("");
    setTeachingStrategy("");
    setOtherField6("");
    setMaterials("");
    setOtherField7("");
    setDeliveryMethod("");
    setOtherField8("");
    setInstructionalDesignModel("");
    setOtherField9("");
    setAssessmentStrategy("");
    setStandard("");
    setOtherField12("");
    setCombinePrompt("");
    setFinalResponse("");
  }

  async function generateContent() {
    try {
      if (combinePrompt) {
        setLoading2(true);
        const response = await chatgpt(combinePrompt);
        setFinalResponse(response);
        setLoading2(false);
      }
    } catch (e) {
      toast.error("Error generating prompt");
      setLoading2(false);
      console.error("Error generating prompt:", e);
    }
  }

  async function regenerateContent() {
    try {
      if (combinePrompt) {
        setLoading3(true);
        const response = await chatgpt(combinePrompt);
        setFinalResponse(response);
        setLoading3(false);
      }
    } catch (e) {
      toast.error("Error regenerating prompt");
      setLoading3(false);
      console.error("Error regenerating prompt:", e);
    }
  }

  return (
    <div className="w-full mx-auto p-0 sm:p-6">
      <ToastContainer />
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-[50%] space-y-4">
          <div className="flex gap-4 items-center justify-between">
            <h2>Filters</h2>{" "}
            <button className="underline text-blue-500" onClick={resetForm}>
              Reset All
            </button>
          </div>

          {/* Field1*/}

          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="degreeLevel"
            >
              DEGREE LEVEL{" "}
              {errors.degreeLevel && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="degreeLevel"
              id="degreeLevel"
              value={degreeLevel}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setDegreeLevel(e.target.value);
                setErrors({ ...errors, degreeLevel: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "Associate",
                "Undergraduate",
                "Graduate",
                "Doctoral",
                "Technical/Vocational",
              ].map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          </div>

          {/* Field2*/}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="courseTitle"
            >
              COURSE TITLE{" "}
              {errors.courseTitle && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <input
              type="text"
              id="courseTitle"
              className={`text-sm border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0`}
              value={courseTitle}
              onChange={(e) => {
                setCourseTitle(e.target.value);
              }}
              placeholder="(e.g., Advanced Mathematical Modeling and Analysis)"
            />
          </div>

          {/* Field3*/}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="lessonTitle"
            >
              LESSON TITLE{" "}
              {errors.lessonTitle && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <input
              type="text"
              id="lessonTitle"
              className={`text-sm border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0`}
              value={lessonTitle}
              onChange={(e) => {
                setLessonTitle(e.target.value);
              }}
              placeholder="(e.g., Introduction to Algebra)"
            />
          </div>

          {/* Field4*/}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="lessonTopic"
            >
              LESSON TOPIC{" "}
              {errors.lessonTopic && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <input
              type="text"
              id="lessonTopic"
              className={`text-sm border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0`}
              value={lessonTopic}
              onChange={(e) => {
                setLessonTopic(e.target.value);
              }}
              placeholder="(e.g., solving linear equations, graphing linear equations)"
            />
          </div>

          {/* Field14 */}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="timing"
            >
              TIMING{" "}
              {errors.timing && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="timing"
              id="timing"
              value={timing}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setTiming(e.target.value);
                setErrors({ ...errors, timing: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {["30 minutes", "45 minutes", "60 minutes", "Other"].map(
                (option) => (
                  <option value={option}>{option}</option>
                )
              )}
            </select>
            {timing === "Other" && (
              <input
                type="text"
                className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                  errors.timing ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="Please specify"
                value={otherField14}
                onChange={(e) => {
                  setOtherField14(e.target.value);
                  setErrors({ ...errors, timing: false });
                }}
              />
            )}
          </div>

          {/* Field5*/}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="learningOutcomes"
            >
              LEARNING OUTCOMES{" "}
              {errors.learningOutcomes && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <textarea
              type="text"
              id="learningOutcomes"
              className={`text-sm border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0`}
              value={learningOutcomes}
              onChange={(e) => {
                setLearningOutcomes(e.target.value);
              }}
              placeholder="List the learning outcomes using a phrase like “On successful completion of this course, students should be able to"
              rows={3}
            ></textarea>
          </div>

          {/* Field6 */}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="teachingStrategy"
            >
              TEACHING STRATEGY{" "}
              {errors.teachingStrategy && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <div className="grid grid-cols-2 gap-3">
              {[
                "Lecture",
                "Discussion",
                "Group activity",
                "Debate",
                "Demonstration",
                "Simulation",
                "Role-play",
                "Socratic",
                "Flipped Classroom",
              ].map((option) => (
                <div>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500 focus:ring-0"
                      checked={teachingStrategy.includes(option)}
                      onChange={() => {
                        setTeachingStrategy((prev) =>
                          prev.includes(option)
                            ? prev.filter((item) => item !== option)
                            : [...prev, option]
                        );
                        setErrors({ ...errors, teachingStrategy: false });
                      }}
                    />
                    <span>{option}</span>
                  </label>
                </div>
              ))}
            </div>
            <div className="mt-3">
              <label className="flex items-center space-x-2">
                <div
                  onClick={handleToggleField6}
                  className="p-0.5 bg-gray-200 rounded-full cursor-pointer text-black"
                >
                  {showOtherField6 ? <Minus size={14} /> : <Plus size={14} />}
                </div>
                <span>Other</span>
              </label>
              {showOtherField6 && (
                <input
                  type="text"
                  className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                    errors.teachingStrategy
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  placeholder="Please specify"
                  value={otherField6}
                  onChange={(e) => {
                    setOtherField6(e.target.value);
                    setErrors({ ...errors, teachingStrategy: false });
                  }}
                />
              )}
            </div>
          </div>

          {/* Field7 */}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="materials"
            >
              MATERIAL(S){" "}
              {errors.materials && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <div className="grid grid-cols-2 gap-3">
              {[
                "Study guides",
                "Flashcards",
                "Quizzes",
                "Worksheets",
                "Audio",
                "Video",
                "Handout",
              ].map((option) => (
                <div>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500 focus:ring-0"
                      checked={materials.includes(option)}
                      onChange={() => {
                        setMaterials((prev) =>
                          prev.includes(option)
                            ? prev.filter((item) => item !== option)
                            : [...prev, option]
                        );
                        setErrors({ ...errors, materials: false });
                      }}
                    />
                    <span>{option}</span>
                  </label>
                </div>
              ))}
            </div>
            <div className="mt-3">
              <label className="flex items-center space-x-2">
                <div
                  onClick={handleToggleField7}
                  className="p-0.5 bg-gray-200 rounded-full cursor-pointer text-black"
                >
                  {showOtherField7 ? <Minus size={14} /> : <Plus size={14} />}
                </div>
                <span>Other</span>
              </label>
              {showOtherField7 && (
                <input
                  type="text"
                  className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                    errors.materials ? "border-red-500" : "border-gray-300"
                  }`}
                  placeholder="Please specify"
                  value={otherField7}
                  onChange={(e) => {
                    setOtherField7(e.target.value);
                    setErrors({ ...errors, materials: false });
                  }}
                />
              )}
            </div>
          </div>

          {/* Field8 */}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="deliveryMethod"
            >
              DELIVERY METHOD{" "}
              {errors.deliveryMethod && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <div className="grid grid-cols-2 gap-3">
              {["Face-to-face", "Remote"].map((option) => (
                <div>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500 focus:ring-0"
                      checked={deliveryMethod.includes(option)}
                      onChange={() => {
                        setDeliveryMethod((prev) =>
                          prev.includes(option)
                            ? prev.filter((item) => item !== option)
                            : [...prev, option]
                        );
                        setErrors({ ...errors, deliveryMethod: false });
                      }}
                    />
                    <span>{option}</span>
                  </label>
                </div>
              ))}
            </div>
            <div className="mt-3">
              <label className="flex items-center space-x-2">
                <div
                  onClick={handleToggleField8}
                  className="p-0.5 bg-gray-200 rounded-full cursor-pointer text-black"
                >
                  {showOtherField8 ? <Minus size={14} /> : <Plus size={14} />}
                </div>
                <span>Other</span>
              </label>
              {showOtherField8 && (
                <input
                  type="text"
                  className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                    errors.deliveryMethod ? "border-red-500" : "border-gray-300"
                  }`}
                  placeholder="Please specify"
                  value={otherField8}
                  onChange={(e) => {
                    setOtherField8(e.target.value);
                    setErrors({ ...errors, deliveryMethod: false });
                  }}
                />
              )}
            </div>
          </div>

          {/* Field9 */}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="instructionalDesignModel"
            >
              INSTRUCTIONAL DESIGN MODEL{" "}
              {errors.instructionalDesignModel && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <div className="grid grid-cols-2 gap-3">
              {[
                "ADDIE (Analysis, Design, Development, Implementation, Evaluation)",
                "SAM (Successive Approximation Model)",
                "Bloom’s Taxonomy",
                "Rapid Prototyping Model",
                "ASSURE Model",
              ].map((option) => (
                <div>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500 focus:ring-0"
                      checked={instructionalDesignModel.includes(option)}
                      onChange={() => {
                        setInstructionalDesignModel((prev) =>
                          prev.includes(option)
                            ? prev.filter((item) => item !== option)
                            : [...prev, option]
                        );
                        setErrors({
                          ...errors,
                          instructionalDesignModel: false,
                        });
                      }}
                    />
                    <span>{option}</span>
                  </label>
                </div>
              ))}
            </div>
            <div className="mt-3">
              <label className="flex items-center space-x-2">
                <div
                  onClick={handleToggleField9}
                  className="p-0.5 bg-gray-200 rounded-full cursor-pointer text-black"
                >
                  {showOtherField9 ? <Minus size={14} /> : <Plus size={14} />}
                </div>
                <span>Other</span>
              </label>
              {showOtherField9 && (
                <input
                  type="text"
                  className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                    errors.instructionalDesignModel
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  placeholder="Please specify"
                  value={otherField9}
                  onChange={(e) => {
                    setOtherField9(e.target.value);
                    setErrors({ ...errors, instructionalDesignModel: false });
                  }}
                />
              )}
            </div>
          </div>

          {/* Field10 */}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="rubricCriteriaLevel"
            >
              RUBRIC CRITERIA LEVEL{" "}
              {errors.rubricCriteriaLevel && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="rubricCriteriaLevel"
              id="rubricCriteriaLevel"
              value={rubricCriteriaLevel}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setRubricCriteriaLevel(e.target.value);
                setErrors({ ...errors, rubricCriteriaLevel: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "7 level-criteria",
                "6 level-criteria",
                "5 level-criteria",
                "4 level-criteria",
                "3 level-criteria",
                "Other",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {rubricCriteriaLevel === "Other" && (
              <input
                type="text"
                className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                  errors.rubricCriteriaLevel
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                placeholder="Please specify"
                value={otherField10}
                onChange={(e) => {
                  setOtherField10(e.target.value);
                  setErrors({ ...errors, rubricCriteriaLevel: false });
                }}
              />
            )}
          </div>

          {/* Field11 */}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="assessmentStrategy"
            >
              ASSESSMENT STRATEGY{" "}
              {errors.assessmentStrategy && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <div className="grid grid-cols-2 gap-3">
              {["Formative", "Summative"].map((option) => (
                <div>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500 focus:ring-0"
                      checked={assessmentStrategy.includes(option)}
                      onChange={() => {
                        setAssessmentStrategy((prev) =>
                          prev.includes(option)
                            ? prev.filter((item) => item !== option)
                            : [...prev, option]
                        );
                        setErrors({ ...errors, assessmentStrategy: false });
                      }}
                    />
                    <span>{option}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Field12 */}
          <div>
            <label
              className="text-gray-600 font-semibold block mb-2"
              htmlFor="standard"
            >
              STANDARD{" "}
              {errors.standard && (
                <small className="text-red-500">* Required</small>
              )}
            </label>
            <select
              name="standard "
              id="standard "
              value={standard}
              className="text-sm cursor-pointer ring-0 border border-gray-300 rounded py-2 px-3 w-full text-gray-700 focus:ring-0 focus:ring-offset-0"
              onChange={(e) => {
                setStandard(e.target.value);
                setErrors({ ...errors, standard: false });
              }}
              required
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {[
                "None",
                "US Common Core Standards",
                "UK National Curriculum Standards",
                "Hong Kong Qualifications Framework (HKQF)",
                "European Qualifications Framework",
                "Other",
              ].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {standard === "Other" && (
              <input
                type="text"
                className={`border rounded py-2 px-3 w-full mt-2 text-sm focus:ring-0 ${
                  errors.standard ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="Please specify"
                value={otherField12}
                onChange={(e) => {
                  setOtherField12(e.target.value);
                  setErrors({ ...errors, standard: false });
                }}
              />
            )}
          </div>
          <br></br>

          <button
            className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            onClick={createPrompt}
            disabled={loading1 || loading2 || loading3}
          >
            {loading1 ? (
              <div className="flex items-center justify-center gap-2">
                <span>Creating... </span>
                <i class="fa-solid fa-spinner animate-spin"></i>
              </div>
            ) : (
              "Create Prompt"
            )}
          </button>
        </div>
        <div className="w-full lg:w-[50%] flex flex-col mt-4">
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <img src={iconImage} className="w-auto max-w-10 max-h-10"></img>
              <div className="flex flex-col">
                <p className="m-0 text-gray-700 font-semibold">
                  Automatic Prompt Generator
                </p>
                <p className="m-0 text-xs text-gray-400 font-semibold">
                  Generate Assessment Task
                </p>
              </div>
            </div>

            <textarea
              className="enhanced-textarea border border-gray-300 py-2 px-3 w-full text-sm focus:ring-0"
              rows={8}
              value={combinePrompt}
              onChange={(e) => {
                setCombinePrompt(e.target.value);
              }}
            ></textarea>
            <button
              className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={generateContent}
              disabled={loading1 || loading2 || loading3}
            >
              {loading2 ? (
                <div className="flex items-center justify-center gap-2">
                  <span>Generating... </span>
                  <i class="fa-solid fa-spinner animate-spin"></i>
                </div>
              ) : (
                "Generate Assessment Task"
              )}
            </button>
          </div>

          <div className="mt-8 p-2 border-gray-200 border-solid border w-full bg-gray-100 rounded-lg overflow-y-auto">
            <div
              id="msg"
              className="p-2 enhanced-textarea max-h-[600px] overflow-y-auto"
            >
              <div className={`mt-4 mb-4 text-left relative`}>
                <span className="inline-block p-4 w-full rounded-md bg-white">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: finalResponse,
                    }}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="mt-4 flex flex-wrap justify-start gap-4">
            <div className="relative">
              <div
                className="flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 cursor-pointer hover:bg-gray-200"
                onClick={() => copyToClipboard(finalResponse)}
              >
                <i className="fas fa-copy mr-2"></i>
                {"Copy"}
              </div>
              {showTooltip && (
                <div className="absolute -top-8 right-0 text-xs rounded bg-black text-white py-1 px-2">
                  Copied!
                </div>
              )}
            </div>
            <div
              className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
                finalResponse
                  ? "cursor-pointer hover:bg-gray-200"
                  : "cursor-not-allowed"
              }`}
              onClick={
                finalResponse
                  ? () => generateAndDownloadPDF()
                  : (e) => e.preventDefault()
              }
              disabled={!finalResponse}
            >
              <i class="fas fa-file-pdf mr-2"></i>
              {"Download Pdf"}
            </div>
            <div
              className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
                finalResponse
                  ? "cursor-pointer hover:bg-gray-200"
                  : "cursor-not-allowed"
              }`}
              onClick={
                finalResponse
                  ? () => generateAndDownloadWord()
                  : (e) => e.preventDefault()
              }
              disabled={!finalResponse}
            >
              <i class="fas fa-file-word mr-2"></i>
              {"Download Word"}
            </div>
            <button
              className={`flex items-center rounded w-auto text-gray-700 px-3 text-sm py-2 border border-gray-300 ${
                finalResponse
                  ? "cursor-pointer hover:bg-gray-200"
                  : "cursor-not-allowed"
              }`}
              onClick={regenerateContent}
              disabled={loading1 || loading2 || loading3}
            >
              {loading3 ? (
                <div className="flex items-center justify-center gap-2">
                  <span>Regenerating... </span>
                </div>
              ) : (
                <div className="flex items-center justify-center gap-2">
                  <RefreshCcw size={20} />
                  <span>Regenerate</span>
                </div>
              )}
            </button>
            <ShareButton finalResponse={finalResponse} />
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import Sidebar, { SidebarItem } from '../Sidebar/Sidebar';
import {
  Home,
  LayoutDashboard,
  BookA,
  BookOpen,
  ClipboardList,
} from 'lucide-react';
import { Outlet, useNavigate } from 'react-router-dom';

const Layout = () => {
  const navigate = useNavigate();

  
  return (
    <div className="bg-white min-h-screen flex">
      <Sidebar>
        <br />
        <SidebarItem
          icon={<Home size={20} />}
          text="Home"
          onClick={() => window.open('https://www.ideal-gen.ai/', '_blank')}
        />
        <SidebarItem
          icon={<LayoutDashboard size={20} />}
          text="Dashboard"
          onClick={() => navigate('/')}
        />
        <hr className="my-3" />
        <SidebarItem
          icon={<BookA size={20} />}
          text="Generate Learning Activity"
          onClick={() => navigate('/learning-activity')}
        />
        <SidebarItem
          icon={<BookOpen size={20} />}
          text="Generate Lesson Plan"
          onClick={() => navigate('/lesson-plan')}
        />
        <SidebarItem
          icon={<ClipboardList size={20} />}
          text="Generate Assessment Task"
          onClick={() => navigate('/assessment-task')}
        />
      </Sidebar>
      <div className="contentContainer flex-grow p-4">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
